
@import url('https://fonts.googleapis.com/css?family=Coming+Soon|News+Cycle|Share');

body {
  background-image: url('./images/backgroundPattern.png');
  margin: 0;
  padding: 0;
  height: 100%;
  min-width: 320px;
}

.page-container {
  min-height: 100vh;
  position: relative;
}

.body-container {
  padding-bottom: 260px;
}
