.footer {
  width: 100%;
  height: 110px;
  position: absolute;
  bottom: 0;
  /* original dark grey rgb(34, 38, 41) */
  background-color: rgba(0, 0, 0, 1);
  font-family: 'Share', serif, Segoe UI, Frutiger, Frutiger Linotype;
  font-weight: 500;
}

.footer > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  color: #FFF;
  width: 80%;
  margin: 10px auto 0;
  padding: 25px 0px 20px 0px;
  bottom: 0;
  overflow: hidden;
}

.footer-name {
  float: left;
  width: 20%;
  font-size: 18px;
  font-family: 'Share', serif, Segoe UI, Frutiger, Frutiger Linotype;
  margin-top: -20px;
}

.footer-credit {
  font-size: 13px;
  margin-top: -10px;
}

.footer-credit > a {
  color: #FFF;
  text-decoration: none;
}

.footer-credit > a:hover {
  color: #D0D0D0;
}

.footer nav {
  float: left;
  width: 60%;
  text-align: center;
  margin: 0 -5%;
}

.footer nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer nav li {
  display: inline-block;
  padding: 0px 40px;
  position: relative;
}

.footer-links {
  color: #FFF;
  text-decoration: none;
  font-size: 24px;
}

.footer-links:hover {
  color: #D0D0D0;
}

.footer-icons {
  float: left;
  width: 20%;
  text-align: right;
  margin: 0;
}

.footer-icons a {
  padding-left: 20px;
}

.footer-icons a:first {
  padding-left: 0px;
}

#mail-img-footer {
  width: 40px;
  padding-bottom: 2px;
}

.footer-social {
  width: 32px;
  height: 32px;
}

/* used to be 1240px before credit line was added */
@media (max-width: 1520px) {
  .footer {
    height: 160px;
  }

  .footer-name {
    float: left;
    width: 50%;
    text-align: center;
    order: 1;
  }

  .footer nav {
    float: left;
    width: 100%;
    order: 2;
    margin: 0;
  }

  .footer nav ul {
    display: flex;
    justify-content: space-evenly;
  }

  .footer nav li {
    padding: 10px 30px 0px 0px;
  }

  .footer-icons {
    float: left;
    width: 50%;
    text-align: center;
    order: 1;
    margin: 0;
  }
}

@media (max-width: 640px) {
  .footer {
    height: 200px;
    margin-top: 50px;
  }

  .footer > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 20px 0px 0px;
  }

  .footer-name {
    float: left;
    width: 100%;
    text-align: center;
    order: 3;
  }

  .footer nav {
    float: left;
    width: 100%;
    order: 1;
    margin: -20px auto 0px;
  }

  .footer-links {
    font-size: 18px;
  }

  .footer nav ul {
    display: flex;
    justify-content: space-evenly;
  }

  .footer nav li {
    padding: 15px 0px 25px;
  }

  .footer-icons {
    float: left;
    width: 100%;
    text-align: center;
    order: 2;
    padding-bottom: 25px;
  }

  .footer-icons a {
    padding: 0px 10px;
  }
}
