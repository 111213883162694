.aboutme-page {
  font-family: 'News Cycle', serif, Segoe UI, Frutiger, Frutiger Linotype;
  font-weight: 500;
  width: 70%;
  margin: 0 auto -60px;
  padding-top: 50px;
}

.aboutme-page::after {
  content: '';
  display: table;
  clear: both;
}

.profile-card {
  width: 30%;
  float: left;
}

.github-profile {
  width: 32px;
}

.linkedin-profile {
  width: 32px;
}

.hide-decoration {
  text-decoration: none;
}

.profile-card-links {
  position: relative;
  left: 20px;
  bottom: 10px;
  color: black;
}

.profile-card-icons {
  padding: 10px 0 0 25px;
}

#personal-photo {
  width: 100%;
  margin-top: 100px;
  border-radius: 50%;
}

#mail-img-about {
  width: 40px;
  padding: 10px 0 0 21px;
}

#send-email {
  position: relative;
  left: 15px;
  bottom: 6px;
}

.aboutme-desc {
  width: 60%;
  float: right;
}

.aboutme-desc > h1 {
  font-size: 72px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}

.aboutme-desc p {
  font-size: 22px;
  text-align: justify;
}

.aboutme-desc ul {
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
  padding: 0;
  list-style: none;
  font-size: 24px;
}

.aboutme-desc li {
  margin: 0px 20px;
  text-align: center;
}

.aboutme-icons img {
  height: 100px;
}

.aboutme-icons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1em;
}

.aboutme-icon-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5em;
  flex: 0 1 16%
}

@media (max-width: 1520px) {
  .aboutme-page {
    width: 80%;
  }

  .profile-card-icons {
    padding-left: 35px;
  }

  #mail-img-about {
    padding-left: 31px;
  }

  .aboutme-desc li {
    margin: 0px 15px;
  }

  .aboutme-icons img {
    height: 100px;
  }

  .aboutme-icon-group {
    flex: 0 1 33%
  }
}

@media (max-width: 1360px) {
  .profile-card {
    width: 35%;
  }

  .profile-card-icons {
    padding-left: 20px;
  }

  #mail-img-about {
    padding-left: 17px;
  }

  .aboutme-desc h1 {
    font-size: 64px;
  }

  .aboutme-desc p {
    font-size: 20px;
  }

  .aboutme-desc ul {
    width: 100%;
    flex-wrap: wrap;
  }

  .aboutme-desc li { 
    width: 20%;
    margin: 0px 30px;
    padding-bottom: 40px;
  }
}

@media (max-width: 1060px) {
  .profile-card {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
  }

  #personal-photo {
    width: 50%;
    margin: 40px 0 20px;
    padding: 0 25%;
  }

  .profile-card-icons {
    margin-left: 0px;
    padding-left: 0px;
  }

  .mobile-icon-margin {
    margin: 0 20px;
  }

  #mail-img-about {
    width: 40px;
    padding: 10px 0 0 11px;
  }

  .profile-card a {
    left: 10px;
    font-size: 14px;
  }

  .aboutme-desc {
    width: 100%;
    margin: 0 auto;
    float: none;
  }

  .aboutme-desc span {
    padding: 0 10px;
  }
}

@media (max-width: 830px) {
  #personal-photo {
    margin-top: 0px;
  }

  .profile-card-icons {
    padding-left: 0px;
  }

  .aboutme-icons {
    margin-bottom: -30px;
  }
}

@media (max-width: 640px) {
  .aboutme-desc > h1 {
    font-size: 48px;
  }

  .aboutme-desc p {
    font-size: 16px;
    text-align: justify;
  }

  #personal-photo {
    width: 80%;
    padding: 0 10%;
  }

  .mobile-icon-margin {
    margin: 0;
  }

  #mail-img-about {
    width: 64px;
    padding-left: 0px;
  }

  .github-profile {
    width: 48px;
  }
  
  .linkedin-profile {
    width: 48px;
  }  

  #send-email {
    display: none;
  }

  .profile-card-links {
    display: none;
  }

  .aboutme-desc p {
    line-height: 140%;
  }

  .aboutme-icons {
    margin-bottom: 30px;
  }

  .aboutme-icons img {
    height: 55px;
  }
  .aboutme-desc ul {
    justify-content: space-evenly;
  }

  .aboutme-desc li { 
    font-size: 14px;
    margin: 0 15px;
    padding-bottom: 25px;
  }

  .aboutme-desc span {
    padding: 0;
  }
}
